import {Component, OnInit} from '@angular/core'
import {environment} from "../environments/environment"
import {KeycloakService} from "keycloak-angular"


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    APPNAME = environment.appname

    isAdmin = this.keycloak.isUserInRole("Admin-Role")
    username: string | undefined
    fullName: string | undefined


    constructor(private keycloak: KeycloakService) {
    }


    async ngOnInit() {
        const profile = await this.keycloak.loadUserProfile()
        this.username = this.keycloak.getUsername()
        this.fullName = `${profile.firstName} ${profile.lastName}`
    }


    logout(event: any) {
        event.preventDefault()
        this.keycloak.logout()
    }
}
