import {KeycloakService} from 'keycloak-angular'
import {environment} from '../environments/environment'


export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            try {
                await keycloak.init({
                    config: {
                        url: environment.keycloak.issuer,
                        realm: environment.keycloak.realm,
                        clientId: environment.keycloak.clientId
                    },
                    loadUserProfileAtStartUp: true,
                    initOptions: {
                        onLoad: 'login-required',
                        checkLoginIframe: true,
                        enableLogging: true,
                    },
                    bearerExcludedUrls: [
                        "/assets",
                        "/svg"
                    ],
                })
                if (keycloak.isUserInRole("Admin-Role")) {
                    resolve()
                } else {
                    keycloak.logout()
                    reject("Admin-Role expected")
                }
            } catch (error) {
                reject(error)
            }
        })
    }
}
