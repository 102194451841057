export const environment = {
    production: true,
    appname: "Timetracking-Admin (TEST)",
    keycloak: {
        issuer: "https://keycloak.activity-time-recording.com/",
        realm: "timetracking_development",
        clientId: "timetracking.admin.test"
    },
    api: {
        baseUrl: "https://test.api.activity-time-recording.com",
    },
    mongoExpress: {
        baseUrl: "https://me.test.api.activity-time-recording.com"
    },
    glitchTip: {
        dsn: "https://7d14de9ed5474bcb92aa551b32b8115c@glitchtip.activity-time-recording.com/2"
    }
}

