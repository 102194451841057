import {APP_INITIALIZER, LOCALE_ID, NgModule, Provider} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {RouteReuseStrategy} from '@angular/router'
import {IonicModule, IonicRouteStrategy} from '@ionic/angular'
import {AppComponent} from './app.component'
import {AppRoutingModule} from './app-routing.module'
import {registerLocaleData} from "@angular/common"
import localeAt from "@angular/common/locales/de"
import {initializer} from "./app.initializer"
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular"


// Locale
registerLocaleData(localeAt)


// // Sentry aktivieren (wenn erlaubt)
// if (environment.production) {
//     Sentry.init({
//         dsn: environment.glitchTip.dsn,
//         autoSessionTracking: false,
//         attachStacktrace: true,
//         maxValueLength: 2000,
//         maxBreadcrumbs: 20,
//     })
//     providers.push({
//         provide: ErrorHandler,
//         useValue: Sentry.createErrorHandler({
//             showDialog: false,
//         }),
//     })
// }


const providers: Provider[] = [
    {
        provide: RouteReuseStrategy,
        useClass: IonicRouteStrategy
    },
    {
        provide: APP_INITIALIZER,
        useFactory: initializer,
        deps: [KeycloakService],
        multi: true
    },
    {
        provide: LOCALE_ID,
        useValue: "de-AT"
    },
]


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        KeycloakAngularModule,
    ],
    providers,
    bootstrap: [AppComponent],
})
export class AppModule {
}
