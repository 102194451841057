<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>

        <!-- Menüliste BEGIN -->
        <ion-list id="menu-list">
          <ion-list-header>{{ APPNAME }}</ion-list-header>
          <ion-note>Administration für Admins</ion-note>

          <!-- Start BEGIN -->
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" routerLink="/start" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="home"></ion-icon>
              <ion-label>Start</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Start END -->

          <!-- Benutzer BEGIN -->
          <ion-menu-toggle auto-hide="false" *ngIf="isAdmin">
            <ion-item routerDirection="root" routerLink="/users" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>Benutzer</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Benutzer END -->

          <!-- Stripe-Kunden BEGIN -->
          <ion-menu-toggle auto-hide="false" *ngIf="isAdmin">
            <ion-item routerDirection="root" routerLink="/stripe-customers" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="id-card"></ion-icon>
              <ion-label>Stripe-Kunden</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Stripe-Kunden END -->

          <!-- Keycloak-Benutzer BEGIN -->
          <ion-menu-toggle auto-hide="false" *ngIf="isAdmin">
            <ion-item routerDirection="root" routerLink="/keycloak-users" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="key"></ion-icon>
              <ion-label>Keycloak-Benutzer</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Keycloak-Benutzer END -->

          <!-- Info BEGIN -->
          <ion-menu-toggle auto-hide="false" *ngIf="isAdmin">
            <ion-item routerDirection="root" routerLink="/info" lines="none" detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="information-circle"></ion-icon>
              <ion-label>Info</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Info END -->


        </ion-list>
        <!-- Menüliste BEGIN -->

        <!-- Current-User Liste BEGIN -->
        <ion-list id="menu-listx">
          <ion-note>{{ fullName }}</ion-note>

          <!-- Logout BEGIN -->
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" (click)="logout($event)" button>
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>Ausloggen</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Logout END -->

        </ion-list>
        <!-- Current-User Liste BEGIN -->

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
