import {NgModule} from '@angular/core'
import {PreloadAllModules, RouterModule, Routes} from '@angular/router'
import {IsAdminAuthGuard} from "../guards/is-admin-auth-guard.service"


const routes: Routes = [
    {
        path: "start",
        loadChildren: () => import("./pages/start/start.module").then(m => m.StartPageModule)
    },
    {
        path: "",
        redirectTo: "start",
        pathMatch: "full"
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
        canActivate: [IsAdminAuthGuard]
    },
    {
        path: 'stripe-customers',
        loadChildren: () => import('./pages/stripe-customers/stripe-customers.module').then(m => m.StripeCustomersPageModule),
        canActivate: [IsAdminAuthGuard]
    },
    {
        path: 'keycloak-users',
        loadChildren: () => import('./pages/keycloak-users/keycloak-users.module').then(m => m.KeycloakUsersPageModule),
        canActivate: [IsAdminAuthGuard]
    },
    {
        path: 'info',
        loadChildren: () => import('./pages/info/info.module').then(m => m.InfoPageModule),
        canActivate: [IsAdminAuthGuard]
    },
]


@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
